

import { BsInstagram, BsLinkedin, BsYoutube, BsTwitter } from "react-icons/bs";
function Find() {
    return (
        <div className="container py-5 text-white text-center">
            <h3 className=" fw-bolder">Connect with Utkarshini <i className="bi bi-arrow-down text-white"></i></h3>

            <div className="row text-white my-5">

                <div className="col-6 col-lg-3">
                <span className='px-2' style={{ fontSize: '70px' }}><a href="https://www.instagram.com/utkarshiniarora/" target="_blank" rel="noopener noreferrer" className='text-decoration-none text-pink '> <BsInstagram /></a>  </span>
                    <div className="py-3">
                   
                        
                    </div>
                </div>
                <div className="col-6 col-lg-3">
                <span className='px-2' style={{ fontSize: '70px' }}><a href="https://www.linkedin.com/in/utkarshiniarora" target="_blank" rel="noopener noreferrer" className='text-decoration-none text-primary '> <BsLinkedin /></a>  </span>
                    <div className="py-3">
                    
                  
                    </div>
                </div>

                <div className="col-6 col-lg-3">
                <span className='px-2' style={{ fontSize: '70px' }}><a href="https://www.youtube.com/@utkarshiniarora" target="_blank" rel="noopener noreferrer" className='text-decoration-none text-danger'> <BsYoutube /></a>  </span>
                    <div className="py-3">
                   
                    </div>
                </div>
                <div className="col-6 col-lg-3">
                <span className='px-2' style={{ fontSize: '70px' }}><a href="https://twitter.com/arorautkarshini" target="_blank" rel="noopener noreferrer" className='text-decoration-none text-info'> <BsTwitter /></a>  </span>
                    <div className="py-3">
                  
                    </div>
                </div>
            </div>

           
        </div>
    )
}

export default Find; 