// function Talks() {
//     return (
//         <div className="container py-5 text-light text-center">
//             <h1>Vijay Talks About </h1>
//             <div className="row container">
//                 <div className="col-xs-12 col-lg-6">
//                     <div className="d-flex">

//                     <i className="bi bi-lightbulb text-warning display-6"></i>
//                         <h5 className="text-center pt-2"> Web3</h5>

//                     </div>
//                 </div>
//                 <div className="col-xs-12 col-lg-6">
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Talks;
function Talks() {
  return (
    <div className="container py-5 text-white text-center">
      <h1 className=" fw-bolder">
        Utkarshini Talks About<i className="bi bi-arrow-down text-white"></i>
      </h1>

      <div className="row py-5">
        <div className="col-xs-12 col-md-6">
          <div className="py-3 d-flex">
            <i className="bi bi-braces text-success display-4"></i>
            <div>
              <h4 className="fw-bolder py-3 text-start px-4">Coding</h4>
              <p className="lead text-start px-4">
                I am a software developer since the last 6 years and has the
                ability to bring any idea to life till production.
              </p>
            </div>
          </div>
        </div>

        <div className=" col-xs-12 col-md-6 ">
          <div className="py-3 d-flex">
            <i className="bi bi-boxes text-purple display-4"></i>
            <div>
              <h4 className="fw-bolder py-3 text-start px-4">Web3</h4>
              <p className="lead text-start px-4">
                Check out my web3 app :{" "}
                <a href="https://www.pesoit.com/">PESO</a>{" "}
              </p>
            </div>
          </div>
        </div>

        <div className=" col-xs-12 col-md-6">
          <div className="py-3 d-flex">
            <i className="bi bi-people-fill text-info display-4"></i>
            <div>
              <h4 className="fw-bolder py-3 text-start px-4">Mentorship</h4>
              <p className="lead text-start px-4">
                I have mentored students in whysicswallah, White Hat Jr (by
                Byjus), and Relevel (by Unacademy) and many more.{" "}
              </p>
            </div>
          </div>
        </div>

        <div className=" col-xs-12 col-md-6">
          <div className="py-3 d-flex">
            <i
              className="bi bi-camera-reels-fill text-danger display-4"
            ></i>
            <div>
              <h4 className="fw-bolder py-3 text-start px-4">
                Content Creation
              </h4>
              <p className="lead text-start px-4">
                I have extended my mentorship to help students content creation
                and have generated over 1M views over various social media
                platforms.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Talks;
