import Namaste from "../assets/Namaste3.png";
import ShinyButton from "./ShinyButton";
function Hero() {
  return (
    <div className="container py-5 text-light">
      <div className="row ">
        <div className="col-sm-12 col-lg-5 align-self-center">
          <div>
            <h2 className="text-muted">I am, </h2>
            <h1>Utkarshini Arora </h1>
            <p className="text-light py-2">
            I am the co founder and CTO of PESO. I am a seasoned software developer, educator and content creator for the last 6 years for many Unicorn startups and Clients. I have mentored over 12,000 students and recently completed a Post Graduation in Blockchain from IIIT Bangalore.

            </p>

            <ShinyButton buttonText='Contact Me' />
          </div>
        </div>
        <div className="col-sm-12 col-lg-7 text-center p-1">
          <img src={Namaste} alt="namaste" className="img-fluid" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
